<template>
<div>
  <div :class="getTheme" class="common-header-style">
    <LanguageSelect :language="lang" @set-lang="(language) => lang = language"></LanguageSelect>
  </div>
  <div
    class="stu-all-form-info iae-style"
    v-if="!initLoading"
  >
    <nav-comp
      :lang="lang"
      :is-require-parent-contact="isRequireParentContact"
      @onClickNavItem="onClickNavItem"
      @handleApplyInfo="handleApplyInfo"
      :navDynamic="navDynamic"
      :hideSubmit="hideSubmitBtn"
      :currentData="currentData"
      :showBackgroundInfo="showBackgroundInfo"
      @export-form="exportForm"
      @all-files-downLoad="allFilesDownLoad"
      :form-type="formType"
    >
    </nav-comp>
    <div class="forms-box">
      <div class='counsellor'>
        <span class="t">编号：</span>
        <span class="n">{{receiptCode}}</span>
        <span class="t">顾问邮箱：</span>
        <owl-input :disabled="hideSubmitBtn" v-model="agentContactEmail" size="small"/>
        <owl-timer class="c-timer" ref="owlTimer" @tick-is-zero="onAutoSave"></owl-timer>
      </div>
      <el-collapse v-model="activeNames">
        <!-- 个人信息 -->
        <el-collapse-item
          :title="t('stuManagement.personalInfo')"
          name="personalInfo"
        >
          <student-info
            class="form-box-item"
            ref="personalInfo"
            :fkPlatformType="fkPlatformType"
            @highLightTab="highLightTab"
            saveApi="saveOrUpdateOnlineStudentInFoData"
            :agentContactEmail="agentContactEmail"
            @on-student-update="onStudentUpdate"
          ></student-info>
        </el-collapse-item>
        <template v-if="!!stuid">
          <!-- 院校申请 -->
          <el-collapse-item
            :title="t('stuManagement.applicationSch')"
            name="applicationSch"
          >
            <student-application
              @getDestinationCountry="getDestinationCountry"
              @updateDestinationCountry="updateDestinationCountry"
              class="form-box-item"
              ref="applicationSch"
              @update="setApplyInsititionTree"
              @get-config="updateConfigByAdd"
              @delete-config="delConfigByApply"
              @on-active-handle="onClickNavItem"
              @highLightTab="highLightTab"
              @update-apply="(updateApply) => getStudentApply(updateApply)"
            ></student-application>
          </el-collapse-item>
          <!-- 背景资料 -->
          <el-collapse-item
            v-show="showBackgroundInfo"
            :title="t('stuManagement.backgroundInfo')"
            name="backgroundInfo"
          >
            <student-background-info
              class="form-box-item"
              ref="backgroundInfo"
              @highLightTab="highLightTab"
            ></student-background-info>
          </el-collapse-item>
          <template v-for="(item, index) in navDynamic">
            <!-- 教育背景-->
            <el-collapse-item
              :key="index + 'education'"
              :title="t('stuManagement.eduBackground')"
              name="education"
              v-if="item.divisionKey == 'education'"
            >
              <student-education-background
                ref="education"
                class="form-box-item"
                :required="item.isRequired"
                @highLightTab="highLightTab"
              ></student-education-background>
            </el-collapse-item>
            <!-- 工作经验 -->
            <el-collapse-item
              :key="index + 'workExperience'"
              :title="t('stuManagement.experience')"
              name="workExperience"
              v-else-if="item.divisionKey == 'workExperience'"
            >
              <student-work-experience
                ref="workExperience"
                class="form-box-item"
                :required="item.isRequired"
                @highLightTab="highLightTab"
              ></student-work-experience>
            </el-collapse-item>
            <!-- 语言成绩 -->
            <el-collapse-item
              :key="index + 'language'"
              v-else-if="item.divisionKey == 'language'"
              :title="t('stuManagement.examination')"
              name="language"
            >
              <student-language-proficiency
                ref="language"
                class="form-box-item"
                :required="item.isRequired"
                @highLightTab="highLightTab"
              ></student-language-proficiency>
            </el-collapse-item>
            <!-- 专业资格证 -->
            <el-collapse-item
              :key="index + 'qualifications'"
              v-else-if="item.divisionKey == 'qualifications'"
              :title="t('stuManagement.profQual')"
              name="qualifications"
            >
              <student-professional-qualification
                class="form-box-item"
                @highLightTab="highLightTab"
                :required="item.isRequired"
                ref="qualifications"
              ></student-professional-qualification>
            </el-collapse-item>
            <!-- 推荐人 -->
            <el-collapse-item
              :key="index + 'recommend'"
              v-else-if="item.divisionKey == 'recommend'"
              :title="t('stuManagement.referee')"
              name="recommend"
            >
              <student-referee
                ref="recommend"
                class="form-box-item"
                :required="item.isRequired"
                :studentApplyInstitutionTree="studentApplyInstitutionTree"
                @highLightTab="highLightTab"
                @on-letter-change="onLetterChange"
              ></student-referee>
            </el-collapse-item>
            <!-- 后续安排 -->
            <el-collapse-item
              :key="index + 'arrangements'"
              v-else-if="item.divisionKey == 'arrangements'"
              :title="t('stuManagement.FollowUpArrangements')"
              name="arrangements"
            >
              <student-accommodation
                ref="arrangements"
                class="form-box-item"
                :required="item.isRequired"
                @highLightTab="highLightTab"
              ></student-accommodation>
            </el-collapse-item>
            <!-- 父母联系方式 id:13 -->
            <el-collapse-item
              :key="index + 'relativesContact'"
              v-else-if="item.divisionKey == 'relativesContact'"
              :title="t('stuManagement.parentContactWay')"
              name="relativesContact"
            >
              <student-parent-contact
                ref="relativesContact"
                class="form-box-item"
                @highLightTab="highLightTab"
                :required="item.isRequired && isRequireParentContact"
              ></student-parent-contact>
            </el-collapse-item>
            <!-- 学生资料上传 id:15 -->
            <el-collapse-item
              :key="index + 'fileData'"
              v-else-if="item.divisionKey == 'fileData'"
              :title="t('stuManagement.stuInfoUpload')"
              name="fileData"
            >
              <student-file-upload
                ref="fileData"
                :toFileCompObj="toFileCompObj"
                :studentFileUploadList="studentFileUploadList"
                class="form-box-item"
                :required="item.isRequired"
                :letters="letters"
                @highLightTab="highLightTab"
              ></student-file-upload>
            </el-collapse-item>
          </template>
        </template>
      </el-collapse>
    </div>
    <apply-confirm
      ref="applyConfrim"
      :dialogVisible.sync="dialogVisible"
      :stufullName="stufullName"
      @handle-save-form="handleSaveForm"
      submitApi="getSubmitOnlineForm"
    ></apply-confirm>
    <div style="display:none">
      <export-to-form ref="exportFormRef"></export-to-form>
    </div>
    <note-book ref="noteBookRef" />
  </div>
</div>
</template>
<script>
import i18n from "@/locale/index";
import mixin from "@/utils/mixin";
import service from "@/utils/request";
import NavComp from "/packages/components/nav-comp/NavComp.vue";
import ApplyConfirm from "/packages/components/ApplyConfirm.vue";
import ExportToForm from "/packages/components/ExportToForm.vue";
import OwlTimer from "/packages/components/OwlTimer.vue";
import OwlInput from "/packages/components/OwlInput.vue";
import NoteBook from "/packages/components/NoteBook.vue";
import LanguageSelect from "/packages/components/LangSelect";
import cryptoJs from '@/utils/cryptoJs';

import {
  getAppCountry,
  getFromConfig,
  loginApi,
  getStudentApplyOnlineForm,
  allDownLoadFileApi
} from "@/api/common.js";
import { checkUserName,checkHostNameUrl } from '@/utils/tool'
export default {
  name: "StudentAllFormInfo",
  watch: {
    lang(newVal) {
      document.documentElement.lang = newVal;
    },
    activeNames:{
      deep: true,
      handler(newVal){
        this.$emit('on-active-names-change', newVal);
      }
    }
  },
  components: {
    NavComp,
    ApplyConfirm,
    ExportToForm,
    OwlTimer,
    OwlInput,
    NoteBook,
    LanguageSelect
  },
  
  provide() {
    return {
      getLocale: () => this.lang,
      getAgentId: () => this.agentId,
      stuid: () => this.stuid,
      ctryOptions: () => this.countryOptions,
      hideSubmitBtn: () => this.hideSubmitBtn,
      platformType: () => this.fkPlatformType,
      formStatus: () => this.formStatus,
      formType: () => this.formType,
      isBmsLogin: () => false,
      companyId: () => -1,
      platformLimitList: () => [],
      issueInnerAccount: () => this.issueInnerAccount,
      isNewAppStatus: () => this.isNewAppStatus,
    };
  },
  mixins: [mixin, i18n],
  data() {
    return {
      formType:checkHostNameUrl(),
      initLoading: true,                // 初始化加载
      agentContactEmail: '',            // 顾问邮箱
      lang: 'zh',
      publicLevel: '5',                 // 公开对象类型
      agentId: -1,
      formAgentId: -1,                  // 当前表单绑定的id
      fkPlatformType: 'get_issue',      // 当前平台类型
      valued:'',
      formStatus: 0,                    // 0新建未提交 1提交申请 2 需要补件 3加申院校
      dialogVisible: false,
      stuid: '',
      at: '',
      onlineFormId: '',                 // 加密id
      receiptCode: '',                  // 编号
      userInfo: {},                     // 免登陆代理信息
      countryOptions: [],
      toFileCompObj: {},                // 传递给文件页的包含国家学校专业等级的对象
      navList: {},                      // 侧边栏
      needUploadFileList: [ 
        {
          id: 0,                        // 学校id
          name: 'Uploaded Public File',
          list: []                      // {id,isMultipleRequired,isActive,name,studentCourseId,typeKey}[]
        }
      ],                                // 需要上传的资料
      studentFileUploadList: [],
      isHideFileUpload: false,
      currentData: "",                  // 当前点击的模块name
      showBackgroundInfo: false,        // 展示背景资料
      stufullName: {},                  // 学生全名
      stuBirthday: '',                  // 生日
      activeNames: [
        "personalInfo",
        "backgroundInfo",
        "applicationSch",
        "education",
        "workExperience",
        "language",
        "qualifications",
        "recommend",
        "arrangements",
        "relativesContact",
        "fileData",
      ],
      studentApplyInstitutionTree: {},  // 申请的院校课程树
      letters: [],                      // 推荐信列表{fileNameOrc: string,fkFileGuid: string, fkStudentInstitutionCourseId:[]}
      issueInnerAccount:false,
      isNewAppStatus: false,
    };
  },
  computed: {
    isMobile(){
      return this.$isMobile();
    },
    getTheme(){
      let className = '';
      if(this.formType==='hti'){
        className = 'hti-header-style';
      }else if(this.formType==='gea'){
        className = 'gea-header-style';
      }
      return className;
    },
    filterNavList() {
      let list = {};
      if (JSON.stringify(this.navList) == "{}") return list;
      for (let key in this.navList) {
        for (let item of this.navList[key]) {
          if (!list[item.divisionKey]) {
            list[item.divisionKey] = item;
          }
        }
      }
      return list;
    },
    navDynamic() {
      return Object.values(this.filterNavList).sort((a, b) => {
        return a.viewOrder > b.viewOrder ? -1 : 1;
      });
    },
    hideSubmitBtn(){
      return this.formStatus !== 0 || this.formAgentId != -1;
    },
    // 学生生日是否超过18岁，超过则父母联系人不必填
    isRequireParentContact() {
      if (this.stuBirthday) {
        const birthdayYear = (new Date(this.stuBirthday)).getFullYear();
        const nowYear = (new Date()).getFullYear();
        if (nowYear - birthdayYear < 18) {
          return true;
        }
      }
      return false;
    },
  },
 
  created() {
    service.interceptors.request.use(
      (config) => {
        config.headers.fkPlatformType = this.fkPlatformType;
        config.headers.publicLevel = this.publicLevel;
        config.headers.langCode = this.lang;
        config.headers.ciphertext = this.ciphertext;
        config.headers.at = this.at;
        config.headers.Authorization = 'Basic aXNzdWVDZW50ZXI6aXNzdWVDZW50ZXI=';
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  },
  async mounted() {
    if(this.$route.query && this.$route.query.onlineFormId){
      this.onlineFormId = this.$route.query.onlineFormId;
    }
    const loading = this.$msge.loading('数据初始化中')
    try {
      await this.autoLogin();
      await this.getCountryOptions();
      await this.getStudentApply();
    } catch (e){
      this.$msge.error('server error')
    }
    loading.close()
    this.initLoading = false;
    if(!this.onlineFormId){
      await this.$nextTick()
      this.$refs.noteBookRef.showNoteBook()
    }
  },
  methods: {
    onStudentUpdate({stuName, birthday, ret}){
      this.stufullName = stuName;
      this.stuBirthday = birthday;
      if (!this.stuid) {
        this.stuid = ret.id;
        this.receiptCode = ret.receiptCode;
        this.onlineFormId = ret.onlineFormId;
        this.$router.replace({path: '/apply-student-online-form', query: {onlineFormId: ret.onlineFormId}})
        this.$msge({
          message: this.t('validate.addSaveSuccess'),
          type: 'success',
          duration: 2000
        })
        this.getStudentApply();
      }
    },
    highLightTab(tabName) {
      this.currentData = tabName;
    },
    
    async getCountryOptions() {
      const { data } = await getAppCountry();
      this.countryOptions = data.datas;
    },
    onClickNavItem(key) {
      this.activeNames = [key];
      this.$emit('sub-menu-click',key);
    },
    // 获取目的国家
    getDestinationCountry({
      type,
      fkAreaCountryId,
      fkAreaCountryname,
      applyLength,
    }) {
      this.showBackgroundInfo = Boolean(applyLength);
      if (type == "add") {
        this.$refs.backgroundInfo.aimCountryAdd(
          fkAreaCountryId,
          fkAreaCountryname
        );
      }
      if (type == "delete") {
        this.$refs.backgroundInfo.deleteDestCtry(fkAreaCountryId);
      }
    },
    // 更新目的国家
    updateDestinationCountry({oldId, newId, newCountryname}) {
      this.$refs.backgroundInfo.updateDestCtry({oldId, newId, newCountryname});
    },
    async getStudentApply(){
      if (!this.onlineFormId) return;
      try {
        let res = await getStudentApplyOnlineForm(this.onlineFormId);
        if (res.data.data) {
          const {
            accommodationDto,               // 后续安排
            studentParentsDto,              // 父母联系方式
            studentInfoDto,                 // 学生信息
            studentCountryItemDto,          // 目标国家
            studentEducationDtos,           // 教育背景
            studentInstitutionCourseDtos,   // 院校申请
            studentQualificationDtos,       // 专业资格
            studentReferenceDtos,           // 推荐人
            studentScoreDtos,               // 考试成绩
            studentWorkDtos,                // 工作经验
            studentDto,                     // 学生信息
          } = res.data.data;
          if (
            studentInstitutionCourseDtos &&
            studentInstitutionCourseDtos.length
          ) {
            let i = 0;
            // 根据选择的学校加载配置
            for await (let item of studentInstitutionCourseDtos) {
              item.uniqueKey = ++i;
              await this.getConfigByApply({
                institutionId: item.fkInstitutionId,
                institutionName: item.fkInstitutionName,
                countryId: item.fkAreaCountryId,
                majorLevelId: item.fkMajorLevelId,
                studentCourseId: item.id,
                courseName: item.fkInstitutionCourseName || item.institutionCourseName,
                isAddApp: item.isAddApp || false
              });
            }
            this.studentFileUploadList = this.needUploadFileList;
            this.setApplyInsititionTree(studentInstitutionCourseDtos)
          }
          
          this.agentContactEmail = studentDto.agentContactEmail;
          this.receiptCode = studentDto.receiptCode
          this.formStatus = Number(studentInfoDto.status);
          this.stufullName = {
            lastName: studentInfoDto.lastName,
            firstName: studentInfoDto.firstName
          }
          this.stuid = studentInfoDto.id
          this.stuBirthday = studentInfoDto.birthday;
          this.formAgentId = studentDto.fkAgentId
          // 给子组件赋值
          setTimeout(()=>{
            this.$refs.personalInfo.setInitData(studentInfoDto);
            this.$refs.applicationSch.setInitData(studentInstitutionCourseDtos)
            this.$refs.applicationSch.getCountryItemDto(studentCountryItemDto)
            this.$refs.education && this.$refs.education[0].setInitData(studentEducationDtos, 1)
            this.$refs.qualifications && this.$refs.qualifications[0].setInitData(studentQualificationDtos)
            this.$refs.workExperience && this.$refs.workExperience[0].setInitData(studentWorkDtos)
            this.$refs.language && this.$refs.language[0].setInitData(studentScoreDtos)
            this.$refs.recommend && this.$refs.recommend[0].setInitData(studentReferenceDtos)
            this.$refs.arrangements && this.$refs.arrangements[0].setInitData(accommodationDto)
            this.$refs.relativesContact && this.$refs.relativesContact[0].setInitData(studentParentsDto);
          
            this.showBackgroundInfo = studentInstitutionCourseDtos && studentInstitutionCourseDtos.length !== 0;
            this.$refs.backgroundInfo.setInitData(studentCountryItemDto)
          })
        } 
        
      } catch {
        this.$router.replace({path:'/apply-student-online-form'})
        this.onlineFormId = ''
        this.$msge.error('onlineFormId 错误或已绑定')
      }
    },
    /**
     * 根据申请学校获取配置
     *  @param {obj.key} 
     *  @param {obj.institutionId}    
     *  @param {obj.institutionName}  // 学校名称
     *  @param {obj.countryId}        // 国家id
     *  @param {obj.majorLevelId}     // 专业id
     *  @param {obj.studentCourseId}  // 申请课程表单id
     *  @param {obj.courseName}       // 课程名称
     *  @param {obj.isAddApp} 当前课程是否加申 
     */
    async getConfigByApply({
      institutionId, 
      countryId, 
      majorLevelId, 
      studentCourseId, 
      courseName, 
      institutionName,
      isAddApp
    }) {
      if(!studentCourseId) return;
      const ret = await getFromConfig(countryId, majorLevelId, institutionId);
      const data = ret.data.data;
      const navList = data? data.appFormDivisionDtos: [];
      const needUploadFileList = data? data.attachmentDtos: [];
      const publicFile = [];
      const privateFile = [];
      
      // 分为公共文件和学校需要单独上传的文件
      if(needUploadFileList && needUploadFileList.length){
        // 分类
        for (let item of needUploadFileList){
          item.studentCourseId = studentCourseId;
          item.isAddApp = isAddApp;
          if(item.isMultipleRequired){  //需单独上传
            privateFile.push(item);
            item.courseName = courseName;
          } else {
            publicFile.push(item);
          }
        }
        // 新增私有的学校上传文件
        if(privateFile.length) {
          // 查找有没有相同的学校
          let institutionItem = this.needUploadFileList.find(item=>item.id == institutionId)
          // 如果没有相同的学校
          if(!institutionItem) {
            institutionItem = {
              name: institutionName,
              id: institutionId,
              list: privateFile,
            }
            this.needUploadFileList.push(institutionItem);
          } else {
            // 如果有，先过滤掉已有的studentCourseId的在新增
            institutionItem.list.push(...privateFile)
          }
        }
        // 新增公共的上传文件
        let pubLng = publicFile.length
        if(pubLng) {
          // 判断是否有新的公共文件
          const file = publicFile.filter(item=>{
            // 判断是否已有此文件id
            const index = this.needUploadFileList[0].list.findIndex(jtem => jtem.id == item.id);
            // 如果有此文件id
            if( index >= 0 ){
              this.needUploadFileList[0].list[index].isAddApp = 1;
              // 判断是否需要加StudentId
              const needAdd = this.needUploadFileList[0].list[index].studentCourseId.toString().split(',').indexOf(studentCourseId+'') >= 0;
              !needAdd && (this.needUploadFileList[0].list[index].studentCourseId += `,${studentCourseId}`)
            }
            return index < 0;
          })
          // 如果有新的公共文件
          if(file.length){
            this.needUploadFileList[0].list.push(...file)
          }
        }
      }

      // 侧边导航栏
      if((!navList || !navList.length) && this.navList[studentCourseId]) {
        this.$delete(this.navList, studentCourseId)
        return;
      }
      if (this.navList[studentCourseId] ) {
        this.navList[studentCourseId] = navList;
      } else {
        this.$set(this.navList, studentCourseId, navList);
      }
      
    },
    // 更新课程添加申请
    async updateConfigByAdd(params){
      // 过滤一遍，如果studentCourseId已有，需要删除
      let index = this.needUploadFileList.length - 1;
      while(this.needUploadFileList.length && index > 0){
        if(!index) break;
        const item = this.needUploadFileList[index]
        const j = item.list.findIndex(item=>item.studentCourseId == params.studentCourseId)
        if(j >= 0) {
          if (item.list.length == 1){
            this.needUploadFileList.splice(index, 1)
          } else {
            item.list.splice(j, 1)
          }
        }
        index--;
      }

      // 请求
      await this.getConfigByApply(params);
      this.studentFileUploadList = this.needUploadFileList;
    },
    // 删除配置，学校课程的资料上传
    delConfigByApply(key) {
      if (this.navList[key]) {
        this.$delete(this.navList, key);
        this.studentFileUploadList = this.studentFileUploadList.filter(item=>{
          const newList = item.list.filter(jtem => {
            const studentCourseId = jtem.studentCourseId.toString().split(',')
            const index = studentCourseId.indexOf(key+'') ;
            if(index >= 0){
              studentCourseId.splice(index, 1);
              jtem.studentCourseId = studentCourseId.join(',')
              return jtem.studentCourseId.length > 0
            }
            return true
          })
          if(newList.length > 0) {
            item.list = newList;
            return true
          } else {
            return false;
          }
        })
        this.needUploadFileList = this.studentFileUploadList;
      }
    },
    // 提交申请
    async handleApplyInfo() {
      const res = await this.checkFormSubmitStatus();
      if (!res ) return
      if (this.stuid) this.dialogVisible = true;
    },
    // 确认
    handleSaveForm() {
      this.dialogVisible = false;
      this.formStatus = 4;
      this.$refs.applyConfrim.checkList = [];
      this.$msge({
        message: this.t("common.saveSucc"),
        type: "success",
        duration: 2000,
      });
    },
    async checkFormSubmitStatus() {
      // if(!this.stuid){
      //   this.$msge.error(this.t('validate.personalInfo'))
      //   return false;
      // }
      const formRefs = [
        "personalInfo",
        // "applicationSch",
        // "backgroundInfo",
        // ...this.navDynamic.map((item) => item.divisionKey),
      ];
      for await(let item of formRefs) {
        let formClip = this.$refs[item];
        if (!formClip) {
          this.$alrt(this.t(`validate.${item}`), "", {
            confirmButtonText: this.t("common.confirm"),
          });
          return false
        }
        formClip = formClip.length? formClip[0]: formClip;
        if (formClip.checkFormStatus) {
          const {error, isEdit, validate} = await formClip.checkFormStatus()
          if(!isEdit || !validate){
            this.$alrt(error, this.t("common.tip"), {confirmButtonText: this.t("common.confirm")})
            this.activeNames = [item];
            return false;
          }
        }
      }
      return true;
    },
    setApplyInsititionTree(list) {
      let institution = {};
      for(let item of list){
        if(institution[item.fkInstitutionId]){
          institution[item.fkInstitutionId].courses.push({
            name: item.fkInstitutionCourseName || item.institutionCourseName,
            fkInstitutionCourseId: item.fkInstitutionCourseId,
            id: item.id,
            disabled: false,
          })
        } else {
          institution[item.fkInstitutionId] = {
            name: item.fkInstitutionName ,
            courses: [{
              name: item.fkInstitutionCourseName || item.institutionCourseName,
              fkInstitutionCourseId: item.fkInstitutionCourseId,
              id: item.id,
              disabled: false,
            }]
          }
        }
      }
      this.studentApplyInstitutionTree = institution;
    },
    onLetterChange(letters){
      if(!letters.length) {
        return this.letters = [];
      }
      this.letters = JSON.parse(JSON.stringify(letters)).sort((a,b)=> b.id-a.id );
    },
    async autoLogin(){
      const username=checkUserName();
      const ret = await loginApi({
        username,
        requestUrlType: location.host,
        password: 'issue1234',
        // password: cryptoJs.encrypt('issue1234'),
        grant_type: 'password'
      })
      if(ret.data.access_token){
        this.at = ret.data.access_token
        this.userInfo = ret.data
      }
    },
    // 导出表单
    exportForm(){
      const formRefs = [
        "personalInfo",
        "applicationSch",
        "backgroundInfo",
        ...this.navDynamic.map((item) => item.divisionKey),
      ];
      for (let item of formRefs) {
        let formClip = this.$refs[item];
        formClip = formClip.length? formClip[0]: formClip;
        if( formClip.hasEdit && formClip.hasEdit() ){
          (item)
          return;
        }
      }
      this.$refs.exportFormRef.exportForm()
    },
    allFilesDownLoad() {
      // 下载全部文件
      if(!this.stuid){
        return
      }
      allDownLoadFileApi(this.stuid).then((res) => {
        if (res) {
          const blob = new Blob([res.data], { type: "application/zip" });
          const elink = document.createElement("a");
          elink.download = this.t("stuManagement.studentInformation");
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        }
      });
    },
    // 自动保存
    async onAutoSave(){
      const loading = this.$msge.loading(this.t('common.autoSaveStart'))
      const formRefs = [
        "personalInfo",
        "applicationSch",
        "backgroundInfo",
        ...this.navDynamic.map((item) => item.divisionKey),
      ];
      try {
        for await (let item of formRefs) {
          let formClip = this.$refs[item];
          formClip = formClip.length? formClip[0]: formClip;
          formClip.autoSave && await formClip.autoSave()
        }
        setTimeout(()=>{
          this.$msge.info(this.t('common.autoSaveOver'))
        },250)
      } catch {}
      
      this.$refs.owlTimer.resetTick()
      loading.close()
      
    }
  },
};
</script>
<style lang="scss">
.el-form.disabled .el-form-item .el-form-item__label,
.el-form .el-form-item__label {
  white-space: pre-line !important;
}
.padding-left {
  padding-left: 20px;
}
.counsellor{
  display: flex;
  align-items: center;
  height: 68px;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 100;
  padding: 0 12px;
  .label{
    font-size: 14px;
  }
  .t{
    font-size: 14px;
    color: #666;
    white-space: nowrap;
  }
  .n{
    color: #333;
    font-size: 14px;
    display: inline-block;
    width: 120px;
    word-break: break-all;
    text-align: left;
    padding-right: 12px;
  }
  .npt{
    width: 218px;
  }
  .c-timer{
    margin-left: auto;
  }
}
@media screen and (max-width: 750px) {
  .counsellor{
    padding-top: 60px;
    height: 100px;
    position: relative;
    .c-timer{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 40px;
    }
  }
}
.common-header-style{
  width:100%;
  height:54px;
  .language {
    margin: 0 auto;
    text-align: right;
    max-width: 1080px;
    color: white;
    line-height: 54px;
    padding-right: 30px;
  }
}
.hti-header-style{
  background: url('../../src/assets/orderbg.jpg') no-repeat;
  background-size: 100% auto;
  background-size: cover;
}
.gea-header-style{
  background: url('../../src/assets/geabg.jpg') no-repeat;
  background-size: 100% auto;
  background-size: cover;
}
@media screen and(max-width: 768px){
  .common-header-style{
    height:27px;
    .language {
      line-height: 27px;
    }
  }
}
</style>
